import { render, staticRenderFns } from "./BrandRewardLogList.vue?vue&type=template&id=105b6c92&scoped=true&"
import script from "./BrandRewardLogList.vue?vue&type=script&lang=js&"
export * from "./BrandRewardLogList.vue?vue&type=script&lang=js&"
import style0 from "./BrandRewardLogList.vue?vue&type=style&index=0&id=105b6c92&prod&lang=scss&scoped=true&"
import style1 from "./BrandRewardLogList.vue?vue&type=style&index=1&id=105b6c92&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "105b6c92",
  null
  
)

export default component.exports